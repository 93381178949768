var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Wrapper for scrolling functionality -->\n<div class=\"sidebar-scroll\">\n    <!-- Sidebar Content -->\n    <div class=\"sidebar-content\">\n        <!-- Brand -->\n        <a data-action=\"goToHome\" href=\"javascript:void(0)\" class=\"sidebar-title\">\n            <i class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"sidebar") : depth0)) != null ? lookupProperty(stack1,"icon") : stack1), depth0))
    + " pull-right\"></i> <strong>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"sidebar") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</strong> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"sidebar") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\n        </a>\n        <!-- END Brand -->\n\n        <!-- User Info -->\n        <div class=\"sidebar-user-box text-light clearfix\">\n\n            <a href=\"#profile\" class=\"pull-left sidebar-user-avatar\">\n                <img src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"avatarUrl") || (depth0 != null ? lookupProperty(depth0,"avatarUrl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"avatarUrl","hash":{},"data":data,"loc":{"start":{"line":15,"column":26},"end":{"line":15,"column":41}}}) : helper)))
    + "\" alt=\"avatar\" class=\"img-circle\">\n            </a>\n\n            <div class=\"sidebar-user-name\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":18,"column":43},"end":{"line":18,"column":51}}}) : helper)))
    + "</div>\n        </div>\n        <!-- END User Info -->\n\n        <!-- Sidebar Navigation -->\n        <div id=\"menu\"></div>\n        <!-- END Sidebar Navigation -->\n\n        <div id=\"sidebar-image\"></div>\n\n    </div>\n    <!-- END Sidebar Content -->\n</div>\n<!-- END Wrapper for scrolling functionality -->";
},"useData":true});