var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header class=\"navbar "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"navCls") : stack1), depth0))
    + "\">\n    <!-- Left Header Navigation -->\n    <ul class=\"nav navbar-nav-custom\">\n        <!-- Main Sidebar Toggle Button -->\n        <li>\n            <a href=\"javascript:void(0)\" id=\"menu-toggle\">\n                <i class=\"fa fa-th-list fa-fw\"></i>\n            </a>\n        </li>\n        <!-- END Main Sidebar Toggle Button -->\n    </ul>\n    <!-- END Left Header Navigation -->\n\n    <!-- Right Header Navigation -->\n    <ul class=\"nav navbar-nav-custom pull-right\">\n        <li class=\"nav-help "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"help") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":16,"column":28},"end":{"line":16,"column":61}}})) != null ? stack1 : "")
    + "\">\n            <a href=\"javascript:void(0)\" id=\"help-toggle\">\n                <i class=\"fa fa-question-circle fa-2x fa-fw\"></i>\n            </a>\n        </li>\n        <!-- User Dropdown -->\n        <li class=\"dropdown\">\n            <a href=\"javascript:void(0)\" class=\"dropdown-toggle\" data-toggle=\"dropdown\">\n                <img src=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"avatarUrl") || (depth0 != null ? lookupProperty(depth0,"avatarUrl") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"avatarUrl","hash":{},"data":data,"loc":{"start":{"line":24,"column":26},"end":{"line":24,"column":41}}}) : helper)))
    + "\" alt=\"avatar\"> <i class=\"fa fa-angle-down\"></i>\n            </a>\n            <ul class=\"dropdown-menu dropdown-custom dropdown-menu-right\">\n                <li class=\"dropdown-header text-center\">"
    + alias1(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias2,"account",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":27,"column":56},"end":{"line":27,"column":86}}}))
    + "</li>\n                <li>\n                    <a href=\"#profile\">\n                        <i class=\"fa fa-user fa-fw pull-right\"></i>\n                        "
    + alias1(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias2,"profile",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":31,"column":54}}}))
    + "\n                    </a>\n                    <!--<a href=\"#modal-user-settings\" data-toggle=\"modal\">-->\n                        <!--<i class=\"fa fa-cog fa-fw pull-right\"></i>-->\n                        <!--"
    + alias1(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias2,"settings",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":35,"column":28},"end":{"line":35,"column":59}}}))
    + "-->\n                    <!--</a>-->\n                </li>\n                <li>\n                    <a href=\"#change-password\">\n                        <i class=\"fa fa-key fa-fw pull-right\"></i>\n                        "
    + alias1(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias2,"change_password",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":41,"column":24},"end":{"line":41,"column":62}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"divider\"></li>\n                <li>\n                    <a href=\"#\" data-action=\"signout\"><i class=\"fa fa-ban fa-fw pull-right\"></i> "
    + alias1(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias2,"logout",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":46,"column":97},"end":{"line":46,"column":126}}}))
    + "</a>\n                </li>\n            </ul>\n        </li>\n        <!-- END User Dropdown -->\n    </ul>\n\n    <h4 class=\"nav-title "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":53,"column":25},"end":{"line":53,"column":59}}})) != null ? stack1 : "")
    + "\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":53,"column":61},"end":{"line":53,"column":70}}}) : helper)))
    + "</h4>\n    <!-- END Right Header Navigation -->\n</header>";
},"useData":true});