/*
 * DOCS: http://select2.github.io/select2/
 */
define([
  'jquery',
  'modules/common/components/locale',
  'modules/shop.common/components/onScreenKeyboard',

  'select2',
],
($, Locale, OnScreenKeyboard) => {
  /**
         * Overwrite open and close to make it compatible with the OnScreenKeyboard
         */

  /** original functions */
  const closeFn = Select2.class.abstract.prototype.close;
  const openFn = Select2.class.abstract.prototype.open;
  const initFn = Select2.class.abstract.prototype.init;

  Select2.class.abstract.prototype.init = function (opts) {
    initFn.call(this, opts); // Trigger the original function first

    // If the OSK is registered. add repositioning
    if (OnScreenKeyboard.isRegistered()) {
      let isOpening = false;
      this.opts.element.on('select2-open', (e) => {
        if (!isOpening) {
          isOpening = true;
          setTimeout(() => {
            // reposition the select 2, cannot find other way to do it
            // this breaks due to keyboard popping up
            this.opts.element.select2('close').select2('open');
            isOpening = false;
          }, 50);
        }
      });
    }
  };

  /** Overwrite open function */
  Select2.class.abstract.prototype.open = function () {
    openFn.call(this); // Trigger the original function first

    // If the OSK is registered. register the input
    if (OnScreenKeyboard.isRegistered()) {
      // this.search is an internal select2 variable that is the search input.
      OnScreenKeyboard.registerTargetInput(this.search);
    }
  };

  /** Overwrite close function */
  Select2.class.abstract.prototype.close = function () {
    closeFn.call(this); // trigger the original function first

    // If the OSK is registered. unregister the input.
    OnScreenKeyboard.isRegistered()
      ? OnScreenKeyboard.unregisterTargetInput() : null;
  };

  /**
         * Translate the default texts.
         */
  $.extend($.fn.select2.defaults, {
    formatMatches(matches) {
      if (matches === 1) {
        return Locale.translate('one_result_is_available_press_enter_to_select_it_dot');
      }
      return Locale.translate('{0}_results_are_available_use_up_and_down_arrow_keys_to_navigate_dot', matches);
    },
    formatNoMatches() {
      return Locale.translate('no_matches_found');
    },
    formatAjaxError(jqXHR, textStatus, errorThrown) {
      return Locale.translate('loading_failed');
    },
    formatInputTooShort(input, min) {
      const n = min - input.length;
      if (n == 1) {
        return Locale.translate('please_enter_one_more_character');
      }
      return Locale.translate('please_enter_{0}_or_more_characters', n);
    },
    formatInputTooLong(input, max) {
      const n = input.length - max;
      if (n == 1) {
        return Locale.translate('please_delete_one_character');
      }
      return Locale.translate('please_delete_{0}_characters', n);
    },
    formatSelectionTooBig(limit) {
      if (limit == 1) {
        return Locale.translate('you_can_only_select_one_item');
      }
      return Locale.translate('you_can_only_select_{0}_items', limit);
    },
    formatLoadMore(pageNumber) {
      return `${Locale.translate('loading_more_results')}…`;
    },
    formatSearching() {
      return `${Locale.translate('searching')}…`;
    },
  });
});
