define([
  'modules/common/acls/acl',
  'modules/upx/collections/users',
  'modules/admin/events/redirect/homePage',
],
(Acl, UsersCollection, RedirectEvent) => Acl.extend({

  authorized() {
    const user = UsersCollection.findWhere({
      active: true,
    });

    return user === undefined;
  },

  error(route) {
    const event = new RedirectEvent({
      previousRoute: route,
    });

    event.trigger();
  },
}));
