define((require) => {
  const Backbone = require('backbone');

  const dashboard = Backbone.Model.extend({
    defaults: {
      widgets: [],
      statistics: [],
      buttons: [],
    },

    initialize() {
      this.set('widgets', new Backbone.Collection([], { comparator: 'priority' }));
      this.set('statistics', new Backbone.Collection([], { comparator: 'priority' }));
      this.set('buttons', new Backbone.Collection([], { comparator: 'priority' }));
    },

    unload() {
      this.clear();
      this.initialize();
    },

    addWidget(widget) {
      this.get('widgets').add(widget);
    },

    addStatistic(statistic) {
      this.get('statistics').add(statistic);
    },

    addButton(button) {
      this.get('buttons').add(button);
    },

    getWidgets() {
      return this.get('widgets');
    },

    getButtons() {
      return this.get('buttons');
    },

    getStatistics() {
      return this.get('statistics');
    },
  });

  return new dashboard();
});
