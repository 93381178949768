define([
  'application',
  'module',
  'backbone',
  'modules/common/views/layout',
  'modules/admin/templates/admin/header',
  'communicator',
  'modules/upx/collections/users',
  'modules/profile/models/profile',
  'modules/upx/components/upx',
  'modules/admin/events/header/signOut',
  'modules/common/components/layout/options',
  'modules/admin/models/navbar',
],
(App, Module, Backbone, Layout, Template, Communicator, Users, ProfileModel, Upx, SignOutEvent, LayoutOptions, NavbarModel) =>
  // ItemView provides some default rendering logic
  Layout.extend({
    // Template HTML string
    template: Template,
    name: 'modules/admin/views/admin/header',

    ui: {
      navTitle: '.nav-title',
    },

    defaults: {
      navCls: 'navbar-default navbar-fixed-top',
    },

    events: {
      'click #menu-toggle': function () {
        Communicator.mediator.trigger('admin/component/sidebar/handle', 'toggle-sidebar');
      },
      'click a[data-action="signout"]': function (e) {
        e.preventDefault();

        const event = new SignOutEvent();
        event.trigger();

        if (event.defaultBehavior) {
          Upx.call('ManagementModule', 'endSession');

          const user = Users.findWhere({ active: true });
          user.logout();
        }
      },
      'click #help-toggle': function () {
        NavbarModel.trigger('help.clicked');
      },
    },

    initialize(options) {
      options = options || {};
      this.options = $.extend(true, {},
        this.defaults,
        LayoutOptions.getData(this.getName()),
        options);

      const self = this;

      ProfileModel.on('change', () => {
        self.$el.find('[alt="avatar"]').attr('src',
          ProfileModel.getAvatarUrl());
      });

      NavbarModel.on('change:title', () => {
        const title = NavbarModel.get('title');

        if (title) {
          self.ui.navTitle.html(title);
          self.ui.navTitle.removeClass('hidden');
        } else {
          self.ui.navTitle.html('');
          self.ui.navTitle.addClass('hidden');
        }
      });
    },

    serializeData() {
      return {
        options: this.options,
        name: ProfileModel.getFullProfileName(),
        avatarUrl: ProfileModel.getAvatarUrl(),
        title: NavbarModel.get('title'),
        help: NavbarModel.get('help'),
      };
    },

    onDestroy() {
      NavbarModel.off('change:title');
    },
  }));
