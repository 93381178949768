define((require) => {
  const Backbone = require('backbone');

  const navbar = Backbone.Model.extend({
    setTitle(title) {
      this.set('title', title);
    },

    unsetTitle() {
      this.unset('title');
    },

    showHelp() {
      this.set('help', true);
    },

    hideHelp() {
      this.set('help', false);
    },
  });

  return new navbar();
});
